import { FC } from 'react';
import { Space } from 'antd';
import SkeletonTag from '@components/skeleton/tag/Tag';
import { Container, Description, SkeletonDate, SkeletonDescription, SkeletonImage, SkeletonTitle, TipsAction } from './Card.style';
const SkeletonTipsAndTricksCard: FC = () => {
  return <Container>
            <SkeletonImage active />
            <SkeletonDate active />
            <SkeletonTitle active />
            <Description>
                <SkeletonDescription active />
                <SkeletonDescription active />
            </Description>
            <TipsAction>
                <Space>
                    <SkeletonTag />
                    <SkeletonTag />
                </Space>
            </TipsAction>
        </Container>;
};
export default SkeletonTipsAndTricksCard;