import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "Titlestyle__Wrapper",
  componentId: "sc-jf8t7-0"
})(["", " @media (max-width:", "){", "}.text{", " @media ( max-width:", "){", " font-size:", "}}.sub-text{", " @media ( max-width:", "){", " font-size:", "}}.extra{", "}"], {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "--tw-text-opacity": "1",
  "color": "rgb(23 23 23 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.xl, {
  "marginLeft": "1rem",
  "marginRight": "1rem"
}, {
  "fontSize": "55px",
  "fontWeight": "700",
  "lineHeight": "60px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "lineHeight": "2.5rem"
}, ({
  $mobileFontSize
}) => `${$mobileFontSize ? $mobileFontSize : 36}px`, {
  "fontSize": "28px",
  "lineHeight": "30px",
  "--tw-text-opacity": "1",
  "color": "rgb(43 43 43 / var(--tw-text-opacity, 1))"
}, ({
  theme
}) => theme.breakpoint.md, {
  "lineHeight": "2rem"
}, ({
  $mobileFontSize
}) => `${$mobileFontSize ? $mobileFontSize : 24}px`, {
  "fontSize": "1.5rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgb(238 132 60 / var(--tw-text-opacity, 1))"
});