import { FC, ReactNode } from 'react';
import { Col } from 'antd';
import { Wrapper } from './Title.style';
interface Props {
  text: ReactNode;
  subText?: ReactNode;
  extra?: ReactNode;
  $mobileFontSize?: number;
  isHeading?: boolean;
}
const Title: FC<Props> = ({
  text,
  subText,
  extra,
  $mobileFontSize,
  isHeading
}: Props) => {
  const renderHeading = () => {
    return isHeading ? <h1 className="text">{text}</h1> : <h2 className="text">{text}</h2>;
  };
  return <Wrapper $mobileFontSize={$mobileFontSize}>
            <Col>
                {renderHeading()}
                <div className="sub-text">{subText}</div>
            </Col>
            <div className="extra">{extra}</div>
        </Wrapper>;
};
export default Title;